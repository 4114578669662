@import 'defaultColours.scss';

@font-face {
  font-family: myFirstFont;
  src: url(src/sass/fonts/Averta-Regular.ttf);
}
@font-face {
  font-family: myFirstFont2;
  src: url(/fonts/Averta-Semibold.ttf);
}

@font-face {
  font-family: AvertaBold;
  src: url(/fonts/Averta-Bold.ttf);
}

//Graphene #204555;
//Coalescent #131E29
//Solway Breeze #E2F5E9
//Solway Breeze Darker #95E1BF
//Spark #FF4713
//Warm Grey #D6D1CA


$contentColour: #D6D1CA;
$bodyTextColour: #131E29;

$buttonColour: #E2F5E9;
$buttonHoverColour: #95E1BF;
$buttonHoverBorderColour: #95E1BF;
$buttonHoverTextColour: #131E29;
$buttonDisabledColour: #6c757d;
$buttonDisabledTextColour: #131E29;
$buttonBorderColour: #E2F5E9;
$buttonTextColour: #131E29;

$loadingBarColour: #FF4713;
$spinnerColour: #FF4713;
$headerColour: #131E29;
$headerKetechTextColour: #D6D1CA;
$headerCMTTextColour: #FF4713;
$loginIconColour: #E2F5E9;
$loginIconColourHover: #E2F5E9;

$sidebarHeaderColour: #204555;
$sidebarHeaderTextColour: #FFFFFF;
$sidebarHeaderCaretColour: #FF4713;
$sidebarHeaderNameColour: #FFFFFF;
$sidebarColour: #131E29;
$sidebarSubHeaderColour: #FFFFFF;
$sidebarCaretColour: #FF4713;
$sidebarHoverColour: #FF4713;
$sidebarMenuItemIconColour: #FFFFFF;
$sidebarMenuOptionColour: #FF4713;
$subMenuPointBorderColour: #FF4713;
$subMenuPointColour: #FF4713;

$dragBoxColour: #FF4713;
$dragBoxTextColour: #131E29;
$dragBoxBorderColour: #FF4713;
$newBoxTextColour: #131E29;
$newBoxColour: #FF4713;
$newBoxBorderColour: #FF4713;
$loadingCogColour: #FF4713;
$checkboxColour: #FF4713;
$checkboxBorderColour: #FF4713;

$modalHeaderColour: #131E29;
$modalHeaderTextColour: #FFFFFF;
$modalHeaderBorderBottomColour: #FF4713;
$modalBodyColour: #D6D1CA;
$modalBodyTextColour: #131E29;
$modalFooterColour: #131E29;
$modalFooterTextColour: #FFFFFF;
$modalFooterBorderTopColour: #FF4713;

$approveConfirmColour: #131E29;
$approveConfirmTextColour: #FFFFFF;

$simulationHeaderColour: #131E29;
$simulationHeaderTextColour: #E2F5E9;
$simulationBodyColour: #131E29;
$simulationBodyTextColour: #E2F5E9;
$simulationDebugPanelColour: #FF4713;

$footerColour: #131E29;
$footerTextColour: #FFFFFF;


//SHOULDN'T BE USED
$primaryColour: #204555; //Graphene
$secondaryColour: #131E29; //Coalescent
$backgroundColour: #131E29; //Coalescent

$headerFont: AvertaBold;
$headerFontColour: #E2F5E9;
$bodyFont: myFirstFont;
$bodyFontColour: #E2F5E9;
$backgroundImage: "/images/awc/awcTocLogo.png";
$destinationImage: "/images/tfw/Logo.jpg";

//Base Default from AWC
@import 'common.scss';
@import 'commonLogo.scss';

//Iframe styling
#simulation {
    background-color: white;
}

#container {
    height: 100%;
    min-height: 100%;
    background: $backgroundColour;
    font-weight:bold;
}

img[src=""] {
    display: none !important;
}

//Contents Styling
.message-panel {
    font-size: 4vw;
    width: 100%;
    height: 9vw;
    max-height: 9vw;
    color: $headerFontColour;
    font-weight: bold;
    vertical-align: middle;
}

.message-panel tr td:nth-of-type(1) {
    width: 10vw;
    max-width: 10vw;
    padding-left: 1vw;
    padding-right: 1vw;
    vertical-align: middle;
}

.message-panel tr td:nth-of-type(2) {
    padding-left: 2vw;
    width: 76vw;
    max-width: 76vw;
    white-space: nowrap;
    vertical-align: middle;
    text-align: left;
}

#htime{
    font-size: 5.5vw;
    padding-top: 1.5vw;
}

#message-icon {
    padding-top: 0.5vw;
    padding-left: 1.5vw;
    height: 9vw;
    max-height: 9vw;
    vertical-align: middle;
}

#message-body {
    display: block;
    vertical-align: middle;
    font-size: 4.5vw;
    width: 90vw;
    color: $bodyFontColour;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 3vw;
}

.message-debug-panel {
    height: .5vh;
    width: 95.5vw;
    border-radius: 5px;
    margin: auto auto 12.2vh;
    background: $simulationDebugPanelColour;
}
