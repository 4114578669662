/* You can add global styles to this file, and also import other style files */
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
@import 'https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~angular-calendar/css/angular-calendar.css';
@import '~@swimlane/ngx-datatable/release/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-switch/3.3.2/css/bootstrap3/bootstrap-switch.css';
@import '~angular-notifier/styles.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import 'assets/css/app.min.css';

.text-uppercase {
    text-transform: uppercase;
}

.ngx-datatable.bootstrap .datatable-header {
    font-weight: bold;
}

.bootstrap-switch-green {
    background-color: #32a932;
    color: #ffffff;
}

.bootstrap-switch-lime {
    background-color: #90ca4b;
    color: #ffffff;
}

.datatable-pager .pager > li > a, .datatable-pager .pager > li > span {
    background-color: unset;
    border: unset;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #333333;
}

.notifier__notification--success {
    color: #001313;
    background-color: #bfeaea;
    border-color: #00acac
}

.notifier__notification--error {
    color: #852f2d;
    background-color: #ffdedd;
    border-color: #ffd1d0
}

.alert.alert-filter {
    background-color: #f2f3f4;
}

/*
 * Responsive text aligning
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */
.text-xs-left {
    text-align: left;
}

.text-xs-right {
    text-align: right;
}

.text-xs-center {
    text-align: center;
}

.text-xs-justify {
    text-align: justify;
}

.error-div {
    color: #ff5b57;
    font-size: .6875rem; 
    margin-top: .25rem;
}

@media (min-width: 768px) {
    .text-sm-left {
        text-align: left;
    }

    .text-sm-right {
        text-align: right;
    }

    .text-sm-center {
        text-align: center;
    }

    .text-sm-justify {
        text-align: justify;
    }
}

@media (min-width: 992px) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }

    .text-md-justify {
        text-align: justify;
    }
}

@media (min-width: 1200px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }

    .text-lg-justify {
        text-align: justify;
    }
}

@media (max-width: 992px) {
    .width-md-full {
        width:100%;
    }
}

@media (min-width: 1200px) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }

    .text-lg-justify {
        text-align: justify;
    }
}

.nav .has-sub a {
    cursor: pointer;
}

.h-20 {
    height: 20%;
}

.h-80 {
    height: 80%;
}

/* Style for the multiselect */
.dropdown-toggle.btn:focus {
    border-color: #5db0ff;
}

.dropdown-toggle.btn.btn-outline.btn-block {
    display: block;
    width: 100%;
    height: calc(1.5em + 14px + 2px);
    padding: 7px .75rem;
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #2d353c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d5dbe0;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

    .dropdown-toggle.btn.btn-outline.btn-block:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }

.datatable-header-cell {
    display: flex !important;
    align-items: flex-end !important;
}

/* #00acac */

body {
    font-size: .875rem;
}

.fade-screen {
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    height: 100%;
    opacity: 0.75;
    background-color: #000000;
    z-index: 10
}

.cmt-page-header {
    font-size: 1.5rem;
}

.approve-confirm-box {
    position: fixed;
    left: 30%;
    width: 40%;
    top: 20%;
    height: auto;
    opacity: 1;
    z-index: 9999;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.approve-change-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading-spinner {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 0px;
    height: 100%;
    opacity: 1;
    z-index: 30;
    padding-left: 50%;
    padding-right: 50%;
    padding-top: 30%;
    color: #00acac
}

.loading-cog {
    color: #00acac
}

.approve-confirm-box-button {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
}

.error-box {
    margin-top: 20px;
    margin-bottom: 20px;
}

.error-text {
    color: red;
    font-weight: bold;
}

.info-text {
    color: white;
    font-size: .75rem;
}

.common-interface-editor-button, .approve-confirm-box-button {
    padding: 10px;
    background-color: #00acac;
    color: white;
    border: none;
    cursor: pointer;
}

.common-interface-button-margins {
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 10px;
}

.header .navbar-nav .dropdown {
    margin-right: 10px;
}

.status-nav {
    line-height: 20px;
    color: rgba(255,255,255,.6);
    display: block;
    text-decoration: none;
}

.dropdown-styling {
    width:100%;
    max-width: 500px;
}

/*Page Sequencing CSS*/
.page-sequence-box {
    margin: 10px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    width: 60%;
    background-color: #00acac;
    color: #ffffff;
}

.page-sequence-box-disabled {
    margin: 10px;
    padding: 6px;
    border-width: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    width: 60%;
    background-color: grey;
    color: #ffffff;
}

.user-box {
    margin: 10px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-color: #00acac;
    color: #ffffff;
}

.user-box-disabled {
    margin: 10px;
    padding: 6px;
    border-width: 5px;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    background-color: grey;
    color: #ffffff;
}

.mat-checkbox-anim-checked-unchecked .mat-checkbox-background {
    animation: none !important;
}

.mat-checkbox-anim-unchecked-checked .mat-checkbox-background {
    animation: none !important;
}

.page-sequence-box-new {
    color: #00acac;
    background-color: #ffffff;
    border-style: dashed;
    border-color: #00acac;
}

.page-sequence-delete-icon {
    cursor: pointer;
}

.page-sequence-items-list {
    display: inline-flex;
    flex-wrap: wrap;
}

.page-sequence-edit-save-button:disabled, .page-sequence-edit-upload-button:disabled {
    background-color: #808080;
    cursor: not-allowed;
}

.page-sequence-edit-enable-checkbox {
}

.mat-checkbox-frame {
    color: #00acac !important;
}

.mat-checkbox-background {
    background-color: #00acac !important;
}

.page-sequence-edit-textbox {
    width: 220px;
    background-color: rgb(232, 240, 254) !important;
    background-image: none !important;
    color: #090909 !important;
}

.page-sequence-edit-name-label {
    width: 65px;
    display: inline-block;
}

.page-sequence-edit-name-textbox-container {
    display: inline-block;
}

.page-sequence-edit-name-section {
    padding: 10px;
}

.page-box:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    opacity: .6;
}

.cdk-drop-list {
    display: flex;
    padding-right: 10px;
    padding-bottom: 10px;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: .3;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-visually-hidden {
    display: none;
}



.page-input-container {
    display: inline-block;
    float: left;
}


.page-edit-repeat-interval-label {
    width: 120px;
    display: inline-block;
}
.page-input-label {
    padding: 7px 0px;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
}
.page-input {
    font-size: 14px;
    width: 60px;
}
.page-filename {
    max-width: 65%;
    min-width: 60%;
    text-align: left;
}

.page-box {
    margin: 10px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    width: 200px;
    border-radius: 10px;
    height: 145px;
    text-align: -webkit-center;
}

.page-box-disabled {
    margin: 10px;
    padding: 5px;
    border-width: 5px;
    border-style: solid;
    background-color: lightgray;
    width: 200px;
    border-radius: 10px;
    height: 145px;
    text-align: -webkit-center;
}
.page-box-configuration {
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
}
.page-thumbnail-box {
    width: 120px;
    height: 120px;
    display: table-cell;
    vertical-align: middle;
}

.page-thumbnail-none {
    padding: 5px;
    border-style: dotted;
    border-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 120px;
    width: 135px;
    white-space: nowrap;
    cursor: pointer;
}

.page-thumbnail {
    width: 120px;
    height: 120px;
    cursor: pointer;
}


.page-name {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
}

.page-delete-icon {
    cursor: pointer;
}

.box-icon {
    padding: 0px 15px;
}


.page-sequence-edit-repeat-time-label {
    width: 120px;
    display: inline-block;
}

.repeat-time-edit-textbox {
    display: inline-block;
    width: 85px;
}

.station-select-edit-textbox {
    display: inline-block;
    width: 200px;
}

.display-None{
    display: none;
}

.max-width-150{
    max-width: 150px;
}

.page-box-selector {
    margin: 10px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    width: 200px;
    border-radius: 10px;
    height:185px;
    text-align: -webkit-center;
}

.footer-badge {
    font-size: 14px;
}

.f-b-bold{
    font-weight: bold;
}

footer {
    position: fixed;
    bottom: 0px;
    width: 100%
}

/*Desktop View CSS*/
@media (min-width: 768px) {
    .unit-info-section-value-mobile {
        display: none;
    }
}
/*Mobile View CSS*/
@media (max-width: 767px) {
    .unit-info-section-value {
        display: none;
        font-size:0px !important;
    }
}