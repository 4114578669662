﻿.toc-image {
  display: flex;
  background: url($backgroundImage) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
}

.toc-logo {
  display: block;
  background: url($destinationImage) no-repeat;
  background-position: center;
  background-size: auto 100%;
  height: 8vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}