﻿:root {
  --primarycolour: #{$primaryColour};
  --backgroundcolour: #{$backgroundColour};
  --header-font-family: #{$headerFont};
  --body-font-family: #{$bodyFont};
}

body {
    font-family: $bodyFont;
    color: $bodyTextColour;
    background-color: $contentColour;
}

//Content background e.g. background of Media page
#content{
    background-color: $contentColour;
    height: calc(100vh - 50px);
}

.footer {
    background-color: $footerColour;
    color: $footerTextColour;
}

#loginButton {
    color: $buttonTextColour;
    background-color: $buttonColour;
    border-color: $buttonBorderColour;
    box-shadow: 0;
}

#loginButton:hover {
    background-color: $buttonHoverColour;
    border-color: $buttonHoverBorderColour;
}

//Styles the loading bar when loading the page
#loading-bar .bar {
    background: $loadingBarColour !important;
    height: 3px !important;
}

//Styles the spinner when loading the page
#loading-bar-spinner {
    color: $spinnerColour !important;
}

//Styles the 'KeTechCMT' header bar
.header.navbar-default {
    background: $headerColour;
}

//Styles the 'CMT' part of the header bar
.header .navbar-brand {
    color: $headerCMTTextColour !important;
}

//Styles the 'Ketech' part of the header bar
.header .navbar-brand b {
    color: $headerKetechTextColour;
}

//Styles user options icon (RHS of the header bar)
.header.navbar-default .navbar-nav > li > a {
    color: $loginIconColour;
}
//Styles user options when selected
.header .navbar-nav > li.show > a, .header .navbar-nav > li.show > a:focus, .header .navbar-nav > li.show > a:hover {
    color: $loginIconColourHover;
}

//Styles the area of the sidebar containing the user's name
.sidebar .nav > li.nav-profile, .sidebar .nav > li.active > a {
    color: $sidebarHeaderTextColour;
    background: $sidebarHeaderColour;
}

//Styles the caret next to the user's name
.sidebar .nav > li.nav-profile .info .caret {
    color: $sidebarHeaderCaretColour;
    opacity: .5 !important;
}

//Style the text of the sidebar containing the user's name
.sidebar .nav > li > a {
    color: $sidebarHeaderTextColour;
}

//Styles the rest of the sidebar navigation area
.sidebar {
    background-color: $sidebarColour;
}

//Styles the sub headers in the nav sidebar
.sidebar .nav > li.nav-header {
    margin: 0;
    padding: 15px 20px 3px;
    line-height: 20px;
    font-size: 11px;
    color: $sidebarSubHeaderColour;
    font-weight: 600;
}

//Sidebar menu option hover colour
.sidebar .nav > li > a:focus, .sidebar .nav > li > a:hover {
    color: $sidebarHoverColour !important;
}

.sidebar .sub-menu > li > a:focus, .sidebar .sub-menu > li > a:hover {
    color: $sidebarHoverColour;
}
//Sidebar menu option selected icon colour
.sidebar .nav > li.active > a i {
    color: $sidebarMenuItemIconColour;
}

//Sidebar menu option active plugin (submenu) text colour
.sidebar .nav .sub-menu > li > a.active, .sidebar .nav > li > a.active {
    color: $sidebarMenuOptionColour;
}

//Sidebar sub menu bullet points
.sidebar .sub-menu > li:after {
    border: 1px solid $subMenuPointBorderColour;
    background: $subMenuPointColour;
}

//Sidebar Caret Colour
.sidebar li > a .caret {
    colour: $sidebarCaretColour;
}

//Styles most buttons within the CMT
.common-interface-editor-button, .approve-confirm-box-button {
    width: 4vw;
    padding: 5px;
    background-color: $buttonColour;
    color: $buttonTextColour;
    border: none;
    cursor: pointer;
}

.common-interface-editor-multiselect {
    padding: 10px;
    background-color: $buttonColour;
    color: $buttonTextColour;
    border: none;
    cursor: pointer;
}

.common-interface-add-button {
    padding: 10px;
    background-color: $buttonColour;
    color: $buttonTextColour;
    border: none;
    cursor: pointer;
}

.common-interface-add-button:disabled {
    background-color: $buttonDisabledColour;
    color: $buttonDisabledTextColour;
    cursor: not-allowed;
}

.common-interface-editor-button:hover {
    background-color: $buttonHoverColour;
    color: $buttonHoverTextColour;
}

.common-interface-editor-button:disabled {
    background-color: $buttonDisabledColour;
    color: $buttonDisabledTextColour;
    cursor: not-allowed;
}

//Styles the boxes used in drag and drop and lists
.common-box {
    background-color: $dragBoxColour;
    color: $dragBoxTextColour;
    border-color: $dragBoxBorderColour;
    cursor: pointer;
}

//Styles 'create new' boxes
.common-box-new {
    color: $newBoxTextColour;
    background-color: $newBoxColour;
    border-style: dashed;
    border-color: $newBoxBorderColour;
}

//Styles loading cog (appears when plugin is loaded)
.loading-cog {
    color: $loadingCogColour;
}

//Styles all checkboxes
.mat-checkbox-background {
    background-color: $checkboxColour !important;
}

.mat-checkbox-frame {
    color: $checkboxBorderColour
}

.media-fullscreen {
    background-color: $primaryColour;
    color: $primaryColour;
}

//Modal Styling
.modal-header {
    background-color: $modalHeaderColour;
    color: $modalHeaderTextColour;
    border-bottom: 1px solid $modalHeaderBorderBottomColour;
}

.modal-body {
    background-color: $modalBodyColour;
    color: $modalBodyTextColour;
}

.modal-footer {
    border-top: 1px solid $modalFooterBorderTopColour;
    background-color: $modalFooterColour;
    color: $modalFooterTextColour;
}

//Approve-dialog confirm box
.approve-confirm-box {
    background-color: $approveConfirmColour;
    color: $approveConfirmTextColour;
}
//Monitoring - info button
.description-box {
    margin-top: 2em;
    margin-bottom: 2em;
    border-style: dotted;
    border-width: 1px;
    border-radius: 1em;
    padding: 1em;
    background-color: $primaryColour;
    color: #f0f0f0;
}

.SequencingSelectListItem {
    background-color: $primaryColour;
}

//Mobile specific css
@media (max-width: 768px) {
    .common-interface-editor-button, .approve-confirm-box-button {
        width: 20vw;
        padding: 5px;
    }

    .footer {
        margin-left: 0px;
    }
}